html, body { 
    width: 100%; 
    height: 100%; 
    margin: 0;
    padding: 0;
}

.img-container {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
}
.img-container img{
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-container .account {
    margin-top: 50px;
}

button {
    color: #333;
    border: 1px solid #70f53c;
    background: #4dd418;
    font-size: 18px;
    margin-top: 20px;

    height: 40px;
    border-radius: 8px;
    padding: 0 24px;
}